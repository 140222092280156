import React from 'react';
import {graphql, useStaticQuery} from 'gatsby';

import Layout from '../components/layout';
import Container from '../components/container';
import PageTitle from '../components/page-title';
import ExpandableSection from '../components/expandable-section';
import CorporateTariff from '../components/corporate-tariff';

type Tariff = {
  text: string;
  table1: {
    title: string;
    data: string[];
  }[];
  table2: {
    one_row: string[][];
    three_rows: {
      title: string;
      class1: string;
      class2: string;
      desc: string;
    }[];
  };
};

type Tariffs = {
  allCorporateTariffsYaml: {
    edges: {
      node: Tariff;
    }[];
  };
  allEnergyStructureYaml: {
    nodes: {
      id: string;
      content: string;
    }[];
  };
  allCorporateArchivalTariffsYaml: {
    edges: {
      node: {
        year: string;
        quarters: {
          quarter: string;
          tariffs: Tariff[];
        }[];
      };
    }[];
  };
  allDiscussionOfDraftTariffsYaml: {
    nodes: {
      title: string;
      content: string;
    }[];
  };
  allFreePriceYaml: {
    nodes: {
      id: string;
      content: string;
    }[];
  }
};

const query = graphql`
  query {
    allCorporateTariffsYaml {
      edges {
        node {
          text
          table1 {
            data
            title
          }
          table2 {
            one_row
            three_rows {
              title
              class1
              class2
              desc
            }
          }
        }
      }
    }
    allEnergyStructureYaml {
      nodes {
        id
        content
      }
    }
    allCorporateArchivalTariffsYaml {
      edges {
        node {
          year
          quarters {
            quarter
            tariffs {
              text
              table1 {
                title
                data
              }
              table2 {
                one_row
                three_rows {
                  title
                  class1
                  class2
                  desc
                }
              }
            }
          }
        }
      }
    }
    allDiscussionOfDraftTariffsYaml {
      nodes {
        title
        content
      }
    }
    allFreePriceYaml {
      nodes {
        id
        content
      }
    }
  }
`;

const CorporateTariffs: React.FC = () => {
  const data = useStaticQuery<Tariffs>(query);
  const corporateTariffs = data.allCorporateTariffsYaml.edges.map(({node}) => node);
  const energyStructure = data.allEnergyStructureYaml.nodes[0];
  const archivalСorporateTariffs = data.allCorporateArchivalTariffsYaml.edges.map(({node}) => node);
  const discussionOfDraftTariffs = data.allDiscussionOfDraftTariffsYaml.nodes.slice(0,2); //Потрібно було лише 23, 24 рік
  const freePrice = data.allFreePriceYaml.nodes;


  return (
    <Layout>
      <Container className="general">
        <PageTitle title="Тарифи" />

        <ExpandableSection summary="Непобутові споживачі">
        {corporateTariffs.map(tariff => (
          <CorporateTariff
            key={tariff.text} 
            text={tariff.text}
            table1={tariff.table1}
            table2={tariff.table2}
          />        
        ))}
        </ExpandableSection>
        {freePrice.map(({id, content}) => (
          <ExpandableSection key={id} summary={id} innerHTML={content}/>
        ))}
        <ExpandableSection key={energyStructure.id} summary={energyStructure.id} innerHTML={energyStructure.content} />
        
        <h2 className="title">Архівні тарифи</h2>
        {archivalСorporateTariffs.map(({year, quarters}) => (
          <>
            <h3 className="title">{year}</h3>
            {quarters.map(({quarter, tariffs}) => (
              <ExpandableSection key={quarter} summary={quarter}>
                {tariffs.map(tariff => (
                  <CorporateTariff key={tariff.text} text={tariff.text} table1={tariff.table1} table2={tariff.table2} />
                ))}
              </ExpandableSection>
            ))}
          </>
        ))}

        <h2 className="title">Обговорення проектів тарифів</h2>
        {discussionOfDraftTariffs.map(({title, content}) => (
            <ExpandableSection key={title} summary={title} innerHTML={content} />
        ))
        }
      </Container>
    </Layout>
  );
};

export default CorporateTariffs;